import { EventTarget } from "event-target-shim";
import configs from "./configs";
import { getCookieToken } from "./get-auth-user";

export default class DashboardChannel extends EventTarget {
  constructor(store, hubId) {
    super();
    this.store = store;
    this.hubId = hubId;
    this.dashboard_api_url_v2 = configs.CUSTOM_API_URL_V2;
    this.hub = null;
  }

  getHub = async () => {
    try {
      if (window.hub && window.hub.id === this.hubId) return window.hub;

      const id = window?.APP?.hub?.hub_id || this.hubId;
      window.hub = await this.request(`/metaverse-rooms/${id}`, "GET");

      this.syncHubSettings(window.hub);

      return window.hub;
    } catch (e) {
      return null;
    }
  };

  syncHubSettings = hub => {
    const hubChannel = APP.hubChannel;

    if (hubChannel) {
      // Merge hub.user_data with the current APP.hub.user_data
      const externalUserData = hub.user_data;
      const currentUserData = APP.hub.user_data;

      if(APP.hub.name !== hub.name) {
        const { description, room_size, user_data, entry_mode, member_permissions } = APP.hub;
        const settings = {
          name: hub.name,
          description,
          room_size,
          user_data,
          entry_mode,
          member_permissions
        }
        hubChannel.updateHub(settings);
        APP.hub.name = hub.name;
      }

      if (externalUserData) {
        if (currentUserData) {
          hub.user_data = {
            ...currentUserData,
            ...externalUserData
          };

          // Check if every value is the same
          if (!Object.keys(currentUserData).every(key => currentUserData[key] === externalUserData[key])) {
            hubChannel.updateHub(hub);
          }
        } else {
          hub.user_data = externalUserData;
          hubChannel.updateHub(hub);
        }
      }
    }
  };

  createHub = async settings => {
    // Add host to settings
    settings.host = "https://" + window.location.host;

    return this.request(`/metaverse-rooms`, "POST", settings);
  };

  updateHub = async settings => {
    return this.request(`/metaverse-rooms/${this.hubId}`, "PUT", settings);
  };

  closeHub = async () => {
    return this.request(`/metaverse-rooms/${this.hubId}`, "DELETE");
  };

  updateHubScene = async sceneId => {
    return this.request(`/metaverse-rooms/${this.hubId}/scene`, "PUT", { scene_id: sceneId });
  };

  updateHubRoom = async settings => {
    return this.request(`/metaverse-rooms/${this.hubId}/room`, "PUT", settings);
  };

  generateQr = async () => {
    try {
      const result = await this.request(`/metaverse-rooms/${window?.APP?.hub?.hub_id || this.hubId}/qr`, "PUT");

      return result;
    } catch (e) {
      return null;
    }
  };

  getQr = async () => {
    if (Object.hasOwn(window.APP, "qrCodeRoom") && this.hubId === window?.APP?.hub?.hub_id) return null;

    try {
      let hub = this.hub && this.hub.id === this.hubId ? this.hub : null;

      if (!hub || this.hubId !== window.APP.hub.hub_id) {
        this.hub = hub = await this.getHub();
      }

      if (hub && !hub?.images_preview_url) {
        await this.updateHubRoom({
          name: hub.name ?? window.APP.hub?.name,
          images_preview_url: window.APP.hub?.scene?.screenshot_url
        });
      }

      if (hub?.qr_code && this.hubId === window?.APP?.hub?.hub_id) return hub.qr_code;

      const existingRoomUpdate = await this.generateQr();

      if (existingRoomUpdate?.qr_code) return existingRoomUpdate.qr_code;

      const hubInfo = window.APP.hub;
      const urlParams = new URLSearchParams(window.location.search);
      const data = {
        creator_assignment_token: null,
        embed_token: hubInfo.embed_token,
        status: "ok",
        name: hubInfo.name,
        id: this.hubId,
        url: window.location.href,
        hidden: 1, // this means that the room is created in metaverse landing, and should not show up
        images_preview_url: window.APP.hub?.scene?.screenshot_url
      };

      const result = await this.createHub(data);

      return result?.qr_code;
    } catch (err) {
      window.APP.qrCodeRoom = null;
      console.error(err);
      return null;
    }
  };

  storeCode = async code => {
    // Get email from credentials
    const email = this.store.state.credentials?.email;

    if (!email) {
      return;
    }

    return this.request(`/email-logins/${email}`, "POST", { token: code });
  };

  loginWithCode = async (code, email) => {
    // Get email from credentials
    if (!email) {
      return;
    }

    try {
      const response = await this.request(`/email-auth/${code}?email=${email}`, "GET");

      if (response?.data?.token) {
        // Add token to local storage
        localStorage.setItem("auth0Token", response.data.token);

        return response.data;
      } else {
        throw new Error("Could not log in with code " + JSON.stringify(response));
      }
    } catch (e) {
      console.error("Error logging in with code", e);

      return;
    }
  };

  request = async (path, method, body) => {
    const dashboard_api_url = configs.CUSTOM_API_URL;

    try {
      const url = `${dashboard_api_url}${path}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      const token = getCookieToken();

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        console.error(`Dashboard API request failed: ${response.status} ${response.statusText}`);

        return null;
      }

      return response.json();
    } catch (e) {
      console.error(e);

      return;
    }
  };

  can(permission) {
    return this.store.state.authUser?.auth0_permissions?.includes(permission);
  }

  storeAvatar = avatar => {
    return this.request(`/metaverse/avatars`, "POST", avatar);
  };

  requestVerification = () => {
    this.request("/verify", "POST");
  };

  request_v2 = async (path, method, body) => {
    try {
      if (!this.dashboard_api_url_v2) return;

      const url = `${this.dashboard_api_url_v2}${path}`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-metaverse-token": this.store.state.credentials.token
      };

      const token = getCookieToken();

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        console.error(`Dashboard API request failed: ${response.status} ${response.statusText}`);

        return null;
      }

      return response.json();
    } catch (e) {
      console.error(e);

      return;
    }
  };

  async canPin() {
    if (!configs.ENABLE_SUBSCRIPTIONS) return null;

    try {
      const userId = this.store.state.authUser?.id;
      const path = `/users/${userId}/can-upload`;
      const data = await this.request_v2(path, "GET");

      if (!data) {
        console.log("Can't pin because the request failed.");
        return null;
      }

      return data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
